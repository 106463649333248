<template>

    <div id="edit-camera">

        <h2 is="sui-header" class="dividing">Edit Camera</h2>
        <sui-form :loading="loading" :error="errorTitle !== ''" :success="successTitle !== ''">
            <success-message :title="successTitle" :reasons="successList"></success-message>
            <error-message :title="errorTitle" :reasons="errorList"></error-message>
            <camera-form :camera="camera" :error="errorWarning"></camera-form>
        </sui-form>

        <sui-divider></sui-divider>
        <sui-button type="submit" color="blue" @click.prevent="save">Update Camera</sui-button>
        <sui-button floated="right" @click.prevent="cancel">Cancel</sui-button>

    </div>
</template>

<script>

    import axios from "axios";


    import CameraForm from "@/views/dashboard/camera/CameraForm";
    import SuccessMessage from "@/components/SuccessMessage";
    import ErrorMessage from "@/components/ErrorMessage";


    export default {
        name: "CameraEdit",

        components: {CameraForm, SuccessMessage, ErrorMessage},
        data: function () {
            return {
                camera: {
                    name: "",
                    intercomNumber: "",

                    location: null,
                    viewer: [],
                    manager: [],

                    awsSecret: "",
                    awsKey: "",
                    awsRegion: "",
                    awsChannel: "",

                    sipNumber: "",
                    sipPassword: "",

                    deviceId: "",
                },

                errorWarning: {
                    errorName: false,
                    errorIntercomNumber: false,
                },

                loading: false,

                errorTitle: "",
                errorList: [],

                successTitle: "",
                successList: [],
            }
        },
        created: async function () {
            this.loading = true;
            axios({
                method: "POST",
                url: "/api/camera/get",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                data: {
                    token: this.$cookies.get('token'),
                    tokenId: this.$cookies.get('tokenId'),
                    cameraId: this.$route.params.cameraId
                }
            }).then((response) => {
                console.log(response)
                if (response.data === "") {
                    this.$router.push('/dashboard/camera');
                }

                this.camera.name = response.data.name;
                this.camera.intercomNumber = response.data.intercomNumber;

                this.camera.location = response.data.locationId;
                this.camera.viewer = response.data.viewer;
                this.camera.manager = response.data.manager;

                this.camera.awsSecret = response.data.awsSecret;
                this.camera.awsKey = response.data.awsKey;
                this.camera.awsRegion = response.data.awsRegion;
                this.camera.awsChannel = response.data.awsChannel;

                this.camera.sipNumber = response.data.sipReference;
                this.camera.sipPassword = response.data.sipPassword;

                this.camera.deviceId = response.data.deviceId;

                this.camera.launchCommand = response.data.launchCommand;

                this.successTitle = "";
                this.errorTitle = "";
            }).catch((err) => {
                console.log(err);
                this.errorTitle = "Cannot load camera profile.";
                this.errorList = [err];
                this.errorList.push("Network connection issue.");
            }).finally(() => {
                this.loading = false;
            });

        },
        methods: {
            cancel: function () {
                this.$router.push('/dashboard/camera');
            },
            formValidation: function () {
                let pass = true;
                if (this.camera.name.trim() === "") {
                    this.errorList.push("Please give this camera a name.");
                    this.errorWarning.errorName = true;
                    pass = false;
                }
                if (this.camera.intercomNumber.trim() === "") {
                    this.errorList.push("Please provide an intercom number for this camera.");
                    this.errorWarning.errorIntercomNumber = true;
                    pass = false;
                }
                if (pass) {
                    this.errorTitle = "";
                    this.errorList = [];
                    this.errorWarning.errorName = false;
                    this.errorWarning.errorIntercomNumber = false;
                } else {
                    this.errorTitle = "Please provide detail information";
                }

                return pass;
            },
            save: function () {
                this.loading = true;
                if (this.formValidation() === false) {
                    this.loading = false;
                    return;
                }

                axios({
                    method: "POST",
                    url: "/api/camera/update",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),

                        cameraId: this.$route.params.cameraId,

                        name: this.camera.name,
                        intercomNumber: this.camera.intercomNumber,

                        location: this.camera.location,
                        viewer: this.camera.viewer,
                        manager: this.camera.manager,

                        awsSecret: this.camera.awsSecret,
                        awsKey: this.camera.awsKey,
                        awsRegion: this.camera.awsRegion,
                        awsChannel: this.camera.awsChannel,

                        deviceId: this.camera.deviceId,

                        launchCommand: this.camera.launchCommand,
                        sipNumber: this.camera.sipNumber,
                        sipPassword: this.camera.sipPassword,
                    }
                }).then((result) => {
                    if (result.data.id) {
                        this.successTitle = "Changes Saved!"
                        this.errorTitle = "";
                        this.errorList = [];
                    } else {
                        this.errorTitle = "Cannot update information!";
                        this.errorList = [];
                        this.errorList.push("Network connection issue.");
                    }
                }).catch((err) => {
                    console.log(err);
                    this.errorTitle = "Cannot update information!";
                    this.errorList = [];
                    this.errorList.push("Network connection issue.");
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
